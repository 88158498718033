import React from "react";
import { graphql, PageProps } from "gatsby";
import { Box } from "grommet";
import { PortableText } from "@portabletext/react";
import type { PortableTextBlock } from "@portabletext/types";

import Section from "@components/Section";
import Seo from "@components/Seo";
import { defaultComponents } from "@utils/PortableText";

type TTermsQuery = Queries.TermsQuery & {
  site: {
    _rawTerms?: PortableTextBlock[];
  };
};

const TermsPage = ({ data: { site }, location }: PageProps<TTermsQuery>) => {
  return (
    <Section constrained="hd" background="grey-3">
      <Seo title="Terms and Conditions" ogUrl={location?.href} />

      <Box pad={{ vertical: "large" }} align="center">
        <Box width={{ max: "730px" }}>
          <PortableText
            value={site?._rawTerms}
            components={defaultComponents({ hLevel: 2 })}
          />
        </Box>
      </Box>
    </Section>
  );
};

export default TermsPage;

export const query = graphql`
  query Terms {
    site: sanitySite {
      _id
      id

      _rawTerms
    }
  }
`;
